<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-title">
            {{ `Date of rendering the page: ${currentTime}` }}
        </div>

        <div class="row">
            <div
                v-for="(tag, index) in settings"
                :key="index"
                class="col-sm-12 col-md-6 col-xl-4 pb-3"
            >
                <single-tag :tag="tag" />
            </div>
        </div>
    </layout>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import SingleTag from '@components/settings/SingleTag';

export default {
    page: {
        title: 'Settings',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        SingleTag
    },

    data() {
        return {
            title: 'Settings',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Settings',
                    active: true
                }
            ],
            settings: {}
        };
    },

    computed: {
        currentTime() {
            return `${moment().format(
                'YYYY-MM-DD HH:mm:ss'
            )}, timezone: ${moment().tz()}`;
        }
    },

    async created() {
        try {
            const parsedSettings = {};
            let settings = await this.getSettings();
            settings = settings.sort((a, b) => (a.tag > b.tag ? 1 : -1));

            settings.map(setting => {
                if (!parsedSettings[setting.tag]) {
                    parsedSettings[setting.tag] = [];
                }

                parsedSettings[setting.tag] = [
                    ...parsedSettings[setting.tag],
                    setting
                ];
            });

            this.settings = { ...parsedSettings };
        } catch (err) {
            console.error(err);

            this.$toasterError();
        }
    },

    methods: {
        ...mapActions({
            getSettings: 'settings/index'
        })
    }
};
</script>
