<template>
    <v-select :value="value" :options="options[settingKey]" @input="onInput" />
</template>

<script>
import options from '@components/settings/options.js';

export default {
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        settingKey: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            options
        };
    },

    methods: {
        onInput(val) {
            this.$emit('input', val);
        }
    }
};
</script>
