<template>
    <input :value="value" type="text" class="form-control" @input="onInput" />
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        }
    },

    methods: {
        onInput($e) {
            this.$emit('input', $e.target.value);
        }
    }
};
</script>
