<template>
    <b-form-checkbox v-model="boolValue" switch />
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: false,
            default: 'false'
        }
    },

    data() {
        return {
            boolValue: false
        };
    },

    watch: {
        value: {
            handler() {
                this.boolValue = this.value === 'true';
            },
            immediate: true
        },

        boolValue() {
            this.$emit('input', this.boolValue ? 'true' : 'false');
        }
    }
};
</script>
