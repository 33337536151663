<template>
    <div class="card h-100">
        <div class="card-body">
            <div class="card-title">
                <h4>{{ name }}</h4>
            </div>
            <div class="d-flex flex-wrap">
                <div class="form-group col-12">
                    <div class="d-flex flex-wrap justify-content-end">
                        <button
                            class="btn btn-success"
                            :disabled="savingInProcess"
                            @click="save"
                        >
                            {{ savingInProcess ? 'Working...' : 'Save' }}
                        </button>
                    </div>
                </div>
                <div
                    v-for="setting in settings"
                    :key="setting.key"
                    class="form-group col-12"
                >
                    <label>{{ setting.title }}</label>
                    <component
                        :is="`${getType(setting.type)}-input`"
                        v-model="setting.value"
                        :setting-key="setting.key"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import startCase from 'lodash/startCase';
import { mapActions } from 'vuex';
import options from '@components/settings/options.js';
import BoolInput from '@components/settings/inputs/Bool';
import IntegerInput from '@components/settings/inputs/Integer';
import SelectInput from '@components/settings/inputs/Select';
import StringInput from '@components/settings/inputs/String';

export default {
    components: {
        BoolInput,
        IntegerInput,
        SelectInput,
        StringInput
    },

    props: {
        tag: {
            type: Array,
            required: true,
            default: () => []
        }
    },

    data() {
        return {
            settings: {},
            name: '',
            savingInProcess: false,
            options,
            refreshNeeded: ['timezone']
        };
    },

    created() {
        this.name = startCase(this.tag[0].tag);
        this.settings = [...this.tag].sort((a, b) => a.order - b.order);
    },

    methods: {
        ...mapActions({
            updateSettingsByTags: 'settings/update'
        }),

        async save() {
            this.savingInProcess = true;

            try {
                const { redirectUrl } = await this.updateSettingsByTags({
                    tag: this.name.toLowerCase().replace(/ /g,"_"),
                    settings: this.settings
                });

                if (redirectUrl) {
                    window.open(redirectUrl, '_blank');
                }

                let successMessage = this.name + ' settings have been updated!';

                if (this.refreshNeeded.includes(this.name.toLowerCase())) {
                    successMessage +=
                        ' The page will be refreshed in 2 seconds to apply changes.';

                    setTimeout(() => {
                        location.reload();
                    }, 2000);
                }

                this.$toaster(successMessage);
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }

            this.savingInProcess = false;
        },

        getType(type) {
            switch (type) {
                case 'int':
                case 'integer':
                    return 'integer';
                case 'bool':
                case 'boolean':
                    return 'bool';
                default:
                    return type;
            }
        }
    }
};
</script>
